<style lang="scss" src="./SkuCard.scss"></style>
<script lang="ts" src="./SkuCard.ts"></script>

<template>
  <q-btn
    v-if="sku && typeof sku === 'string' && !sku.includes('-ALL-')"
    class="button table-btn copy"
    flat
    color="primary"
    v-on:click.stop="copySku"
  >
    <font-awesome-icon icon="fa-light fa-barcode-read" size="2xl" class="blue" />
    <q-tooltip class="font-size-14 background-color-29A0E8">
      <span class="text-center min-width-200 display-inline_block">
        {{ isCoppied ? $t("sku.copied") : sku }}
      </span>
    </q-tooltip>
  </q-btn>
  <div v-else-if="sku && typeof sku === 'object' && !sku.includes('-ALL-')">
    <q-btn
      class="button table-btn copy"
      flat
      color="primary"
      v-on:click.stop="isDialog = true"
    >
      <font-awesome-icon icon="fa-light fa-barcode-read" size="2xl" class="blue" />
      <q-tooltip class="font-size-14 background-color-29A0E8">
        <span v-for="item in sku" :key="item" class="block text-center min-width-200">
          {{ item }}
        </span>
      </q-tooltip>
    </q-btn>
    <span class="blue font-weight-600 font-size-12">x{{ sku.length }}</span>
  </div>
  <q-btn v-else class="button table-btn copy" flat color="grey-8">
    <font-awesome-icon icon="fa-light fa-ban" size="2xl" class="blue" />
    <q-tooltip class="font-size-14 background-color-29A0E8">
      {{ $t("table.noSKU") }}
    </q-tooltip>
  </q-btn>
  <q-dialog v-model="isDialog" position="left">
    <q-card style="transform: translateX(35%); min-width: 400px">
      <q-card-section>
        <div class="font-weight-600 font-size-24">{{ $t("sku.copySKUs") }}</div>
        <div class="font-weight-600 font-size-18">{{ props.rowData.Name }}</div>
      </q-card-section>
      <q-separator horizontal />
      <q-card-section>
        <div v-for="item in sku" :key="item">
          <q-checkbox
            class="text-weight-medium"
            v-model="checkedSKUs"
            :val="item"
            :label="item"
            :true-value="item"
            @update:model-value="selectedCheck"
          />
        </div>
      </q-card-section>
      <q-separator horizontal />
      <q-card-actions>
        <div class="row fit justify-between items-center text-weight-medium blue">
          <div>
            <q-checkbox
              class="font-size-16"
              v-model="allArr"
              :val="sku"
              :label="$t('sku.all')"
              @update:model-value="allChecked"
            />
          </div>
          <div>
            <q-btn
              class="font-size-16"
              flat
              :label="$t('sku.cancel')"
              v-close-popup
              @click="checkedSKUs = []"
            />
            <q-btn
              @click="multipleCopy"
              class="font-size-16"
              flat
              :label="$t('sku.ok')"
              v-close-popup
            />
          </div>
        </div>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
